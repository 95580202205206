const d = {
    'form.save':'Lưu',
    'form.cancel':'Hủy',
    'form.delete':'Xoá',
    'form.datePicker.today':'Hôm nay',
    'form.datePicker.nextYear':'Năm tới',
    'form.datePicker.previousYear':'Năm ngoái',
    'form.datePicker.month':'Tháng',
    'form.datePicker.year':'Năm',
    'menu.dashboard':'Tổng quan',
    'menu.product':'Sản phẩm',
    'menu.order': 'Đơn hàng',
    'menu.report': 'Báo cáo',
    'menu.customer':'Khách hàng',
    'menu.inventory': 'Tồn Kho',
    'menu.affiliate':'Cộng tác viên',
    'menu.category':'Danh mục',
    'menu.supplier':'Nhà cung cấp',
    'menu.warehouse':'DS Kho',
    'menu.setting':'Cài đặt',
    'menu.slider': 'Slider',
    'menu.banner': 'Banner',
    'app.category.addNew':'Tạo mới',
    'app.category.addNew.title':'Tạo mới danh mục',
    'app.category.name':'Tên danh mục',
    'app.category.name.required': 'Nhập tên danh mục',
    'app.category.includeInTopMenu':'Hiển thị trên menu',
    'app.category.parent':'Danh mục cha',
    'app.category.displayOrder':'Thứ tự hiển thị',
    'app.category.displayOnTopMenu':'Hiển thị trên top menu',
    'app.category.showOnHomepage':'Hiển thị ngoài trang chủ',
    'app.category.addNew.success':'Thêm danh mục thành công',
    'app.category.addNew.noReturnData': 'Lỗi không có dữ liệu trả về',
    'app.category.edit.success':'Cập nhật danh mục thành công',
    'app.category.delete.success':'Danh mục đã được xóa',
    'app.category.delete.fail':'Lỗi, không xóa được danh mục',
    'app.category.delete.title':'Xóa danh mục sản phẩm',
    'app.category.delete.desc':'Bạn có chắc muốn xóa danh mục sản phẩm này?',
    'app.product.addNew':'Tạo sản phẩm',
    'app.product.edit.title':'Sửa sản phẩm: {{name}}',
    'app.product.addNew.title':'Tạo sản phẩm mới',
    'app.product.name':'Tên sản phẩm',
    'app.product.displayOrder':'Thứ tự hiển thị',
    'app.product.name.required':'Nhập tên sản phẩm',
    'app.product.price':'Giá bán',
    'app.product.oldPrice':'Giá cũ',
    'app.product.productCost':'Giá gốc',
    'app.product.category':'Danh mục',
    'app.product.shortDescription':'Mô tả ngắn',
    'app.product.fullDescription':'Mô tả chi tiết',
    'app.product.addNew.success':'Thêm sản phẩm thành công',
    'app.product.edit.success':'Cập nhật sản phẩm thành công',
    'app.product.delete.title':'Xóa sản phẩm',
    'app.product.delete.desc':'Bạn có chắc muốn xóa sản phẩm này?',
    'app.product.delete.success':'Sản phẩm đã được xóa',
    'app.product.delete.fail':'Lỗi, không xóa được sản phẩm',
    'app.order.orderNum':'Mã đơn hàng',
    'app.order.orderStatus':'Trạng thái',
    'app.order.orderStatus.Pending':'Chờ xử lý',
    'app.order.orderStatus.Processing':'Đang xử lý',
    'app.order.orderStatus.Complete':'Hoàn thành',
    'app.order.orderStatus.Cancelled':'Đã hủy',
    'app.order.paymentStatus':'Thanh toán',
    'app.order.paymentStatus.Pending':'Chờ xử lý',
    'app.order.paymentStatus.Authorized':'Chờ xác nhận thanh toán',
    'app.order.paymentStatus.Paid':'Đã thanh toán',
    'app.order.paymentStatus.PartiallyRefunded':'Hoàn tiền một phần',
    'app.order.paymentStatus.Refunded':'Hoàn tiền',
    'app.order.paymentStatus.Voided':'Đã xuất hóa đơn',
    'app.order.shippingStatus':'Vận chuyển',
    'app.order.shippingStatus.NotYetShipped':'Chưa giao hàng',
    'app.order.shippingStatus.ShippingNotRequired':'Không cần giao hàng',
    'app.order.shippingStatus.Shipped':'Đã giao đơn vị vận chuyển',
    'app.order.shippingStatus.Delivered':'Giao hàng thành công',
    'app.order.customer':'Khách hàng',
    'app.order.createdOn':'Ngày tạo',
    'app.order.orderTotal':'Tổng',
    'app.order.view':'Chi tiết',
    'app.order.createNew':'Tạo đơn hàng',
    'app.order.filter.dateFrom':'Từ ngày',
    'app.order.filter.dateTo':'Đến ngày',
    'app.order.filter.search':'Tìm kiếm',
    'app.order.filter.orderStatus':'Trạng thái',
    'app.order.filter.orderStatus.all':'Tất cả',
    'app.order.filter.paymentStatus': 'Thanh toán',
    'app.order.filter.paymentStatus.all':'Tất cả',
    'app.order.detail':'Chi tiết',
    'app.order.note':'Ghi chú',
    'app.order.addNew.success':'Thêm đơn hàng thành công',
    'app.order.edit.title':'Sửa đơn hàng : #{{orderNumber}}',
    'app.order.orderItem.orderNum':'STT',
    'app.order.orderItem.productName':'Sản phẩm',
    'app.order.orderItem.price':'Đơn giá',
    'app.order.orderItem.quantity':'Số lượng',
    'app.order.orderItem.total':'Thành tiền',
    'app.order.orderItem.delete.title':'Xóa sản phẩm trong đơn hàng',
    'app.order.orderItem.delete.desc':'Bạn chắc chắn muốn xóa sản phẩm trong đơn hàng này?',
    'app.table.action':'',
    'app.table.edit':'Sửa',
    'app.table.delete':'Xóa',
    'app.table.history':'Lịch sử',
    'app.inventory.productName':'Tên sản phẩm',
    'app.inventory.stockQuantity': 'SL trong kho',
    'app.inventory.import':'Nhập kho',
    'app.inventory.createImport':'Tạo nhập kho',
    'app.inventory.export':'Xuất kho',
    'app.inventory.createExport':'Tạo xuất kho',
    'app.inventory.warehouseList':'DS kho',
    'app.inventory.importQuantity':'Số lượng',
    'app.inventory.importPrice':'Giá nhập',
    'app.inventory.importSubTotal':'Thành tiền',
    'app.inventory.import.enterProductName.placeholder':'Nhập tên hoặc mã sản phẩm',
    'app.inventory.import.supplier':'Nhà cung cấp',
    'app.inventory.import.supplierInvoiceCode':'Mã hoá đơn nhà cung cấp',
    'app.inventory.import.supplierInvoiceDate':'Ngày hoá đơn nhà cung cấp',
    'app.inventory.import.date':'Ngày giờ nhập kho',
    'app.inventory.import.importCode':'Mã phiếu nhập',
    'app.inventory.import.importCode.auto':'Hệ thống tự tạo',
    'app.inventory.import.warehouse':'Kho',
    'app.inventory.import.note':'Ghi chú',
    'app.inventory.import.tax':'Thuế',
    'app.inventory.import.totalQuantity':'Tổng số lượng hàng',
    'app.inventory.import.subTotal':'Tiền hàng',
    'app.inventory.import.total':'Thành tiền',
    'app.inventory.import.prePay':'Trả trước cho nhà cung cấp',
    'app.inventory.import.remain':'Còn nợ',
    'app.inventory.import.addProduct':'Thêm',
    'app.supplier.addNew':'Thêm nhà cung cấp',
    'app.supplier.name':"Tên nhà cung cấp",
    'app.supplier.addNew.title': "Tạo mới nhà cung cấp",
    'app.supplier.name.required': "Nhập tên nhà cung cấp",
    'app.supplier.description':'Ghi chú',
    'app.supplier.addNew.success':'Thêm nhà cung cấp thành công',
    'app.supplier.edit.success':'Sửa nhà cung cấp thành công',
    'app.supplier.delete.title':'Bạn có chắc rằng bạn muốn xoá nhà cung cấp này?',
    'app.supplier.delete.desc':'Bạn sẽ không xoá được nhà cung cấp nếu đã từng nhập hàng từ họ?',
    'app.warehouse.addNew':'Thêm kho',
    'app.warehouse.name':"Tên kho",
    'app.warehouse.addNew.title': "Tạo mới kho",
    'app.warehouse.name.required': "Nhập tên kho",
    'app.warehouse.description':'Ghi chú',
    'app.warehouse.addNew.success':'Thêm kho thành công',
    'app.warehouse.edit.success':'Sửa kho thành công',
    'app.warehouse.delete.title':'Bạn có chắc rằng bạn muốn xoá kho này?',
    'app.warehouse.delete.desc':'Bạn sẽ không xoá được kho nếu đã từng lưu trữ hàng trong kho?',
    'app.warehouse.delete.success':'Xoá kho thành công',
    'app.inventoryImport.warehouse.required': 'Vui lòng chọn kho',
    'app.inventoryImport.supplier.required':'Vui lòng chọn nhà cung cấp',
    'app.inventoryImport.selectProduct':'Vui lòng chọn sản phẩm',
    'app.inventory.import.submit.success':'Lưu phiếu nhập thành công',
    'app.datepicker.placeholder':'DD-MM-YY',
    'app.inventory.export.date': 'Ngày xuất',
    'app.inventory.export.exportCode':'Mã phiếu xuất',
    'app.inventory.export.warehouse':'Kho',
    'app.inventory.export.customer':'Khách hàng',
    'app.inventory.exportPrice':'Giá xuất',
    'app.customer.addNew':'Thêm khách hàng',
    'app.customer.addNew.success':'Thêm khách hàng thành công',
    'app.customer.edit':'Sửa khách hàng',
    'app.customer.edit.success':'Sửa thông tin khách hàng thành công',
    'app.customer.firstName':'Tên',
    'app.customer.lastName': 'Họ',
    'app.customer.phoneNumber': 'Số điện thoại',
    'app.customer.firstName.required':'Nhập tên khách hàng',
    'app.customer.lastName.required':'Nhập họ khách hàng',
    'app.customer.phoneNumber.required':'Nhập số điện thoại',
    'app.customer.fullName':'Tên khách hàng',
    'app.customer.email':'Email',
    'app.customer.address':'Số nhà, đường',
    'app.customer.address.province':'Tỉnh/Thành phố',
    'app.customer.address.district':'Quận/Huyện',
    'app.customer.address.ward':'Phường/Xã',
    'app.customer.delete.title': 'Xóa khách hàng',
    'app.customer.delete.desc': 'Bạn có chắc muốn xóa khách hàng này không?',
    'app.inventory.export.paymentAmount':'KH thanh toán',
    'app.inventoryExport.selectProduct':'Vui lòng chọn sản phẩm',
    'app.inventory.export.submit.success':'Lưu phiếu xuất thành công',
    'app.inventoryExport.customer.required':'Vui lòng chọn khách hàng',
    'app.setting.slider.imageUrl':'Hình ảnh',
    'app.setting.slider.title':'Tiêu đề',
    'app.setting.slider.desc':'Mô tả',
    'app.setting.slider.link':'Link',
    'app.setting.slider.add':'Thêm slider',
}

export default d;