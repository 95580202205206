import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import en from "./locales/en-US";
import vi from "./locales/vi-VN";

const resources = {
  vi: { translation: vi },
  "vi-VN": { translation: vi },
  en: { translation: en },
  "en-US": { translation: en },
  vi: { translation: vi }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: "vi",
    fallbackLng: 'vi',
    debug: true,
    keySeparator: false,
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;
