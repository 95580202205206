import { render } from "react-dom";
import reportWebVitals from './reportWebVitals';
import "./i18n";
import "./css/index-light.less";

import { getJwtToken } from "./PublicPortal/stores/loginUtil";
import { aFetch, setAuthtoken } from "./services/api/fetch";

import {configure} from "mobx";
import { JwtTokenKey } from "./config";
if (process.env.NODE_ENV == "development") configure({
    enforceActions:"observed",
}); else configure({ enforceActions: "never" });

const rootEle = document.getElementById("root");

async function goToLogin() {
    window.location.href = `${process.env.REACT_APP_WEB}/Account/Login`
}

async function bootstrap() {
    const mApp = import("./UserPortal/App");

    if (!mApp) {
        alert(`No roles assigned for this account.`);
        goToLogin();
        return;
    }

    const { App } = await mApp;
    render(<App />, rootEle);
}

bootstrap();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
